@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Medium.eot');
  src: url('../fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Montserrat-Medium.woff2') format('woff2'),
      url('../fonts/Montserrat-Medium.woff') format('woff'),
      url('../fonts/Montserrat-Medium.ttf') format('truetype'),
      url('../fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arial MT';
  src: url('../fonts/ArialMTBlack.eot');
  src: url('../fonts/ArialMTBlack.eot?#iefix') format('embedded-opentype'),
      url('../fonts/ArialMTBlack.woff2') format('woff2'),
      url('../fonts/ArialMTBlack.woff') format('woff'),
      url('../fonts/ArialMTBlack.ttf') format('truetype'),
      url('../fonts/ArialMTBlack.svg#ArialMTBlack') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: 'Roboto';
//   src: url('..fonts/Roboto-Regular.eot');
//   src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
//       url('../fonts/Roboto-Regular.woff2') format('woff2'),
//       url('../fonts/Roboto-Regular.woff') format('woff'),
//       url('../fonts/Roboto-Regular.ttf') format('truetype'),
//       url('../Roboto-Regular.svg#Roboto-Regular') format('svg');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Medium.eot');
  src: url('../fonts/Lato-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Lato-Medium.woff2') format('woff2'),
      url('../fonts/Lato-Medium.woff') format('woff'),
      url('../fonts/Lato-Medium.ttf') format('truetype'),
      url('../fonts/Lato-Medium.svg#Lato-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Georgia';
  src: url('../fonts/Georgia.eot');
  src: url('../fonts/Georgia.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Georgia.woff2') format('woff2'),
      url('../fonts/Georgia.woff') format('woff'),
      url('../fonts/Georgia.ttf') format('truetype'),
      url('../fonts/Georgia.svg#Georgia') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@media (max-width: 1024px) {

  .container {
    padding-left: 24px;
    padding-right: 24px;
    width: 100% !important;
  }


}
@tailwind base;
@tailwind components;
@tailwind utilities;
